import mixpanel from 'mixpanel-browser';

if (['local', 'staging'].includes(process.env.REACT_APP_ENV || '')) {
  console.log('Initializing Mixpanel for staging');
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || '', { debug: true });
} else if (['production'].includes(process.env.REACT_APP_ENV || '')) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || '');
} else {
  console.log(`Purposely Registering Invalid MixPanel Token For Env: ${process.env.REACT_APP_ENV}`);
  mixpanel.init('INVALID', { debug: true });
}

export default mixpanel;
